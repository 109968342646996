/// <reference path="o365.pwa.declaration.sw.DataObjectFieldUtilities.d.ts" />

import type Field from 'o365.modules.DataObject.Field.ts';
import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { ISortByObjectSorter } from 'o365.pwa.declaration.sw.FastSort.d.ts';

import type * as DataObjectFieldUtilitiesModule from "o365.pwa.declaration.sw.DataObjectFieldUtilities.d.ts";

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    enum SortDirection {
        Desc = 'desc',
        Asc = 'asc'
    }

    enum GroupByAggregates {
        Avg = "AVG",
        Count = "COUNT",
        Count_Distinct = "COUNT_DISTINCT",
        Sum = "SUM",
        Max = "MAX",
        Min = "MIN"
    }

    class DataObjectFieldUtilities {
        static getSortColumns(fields: Array<Field>): Array<DataObjectFieldUtilitiesModule.ISortColumns> {
            const sortColumns = fields.map<DataObjectFieldUtilitiesModule.ISortColumns | null>(field => {
                if ("sortOrder" in field && "sortDirection" in field) {
                    let name = null;
                    
                    if ("alias" in field) {
                        name = field.alias;
                    } else if ("name" in field) {
                        name = field.name;
                    }

                    const { sortOrder, sortDirection = "asc" } = field;
                    
                    return { name, sortOrder, sortDirection };
                }

                return null;
            }).filter((item: DataObjectFieldUtilitiesModule.ISortColumns | null) => {
                return item !== null && (item.hasOwnProperty("sortOrder") && item.sortOrder !== null);
            }) as Array<DataObjectFieldUtilitiesModule.ISortColumns>;

            return sortColumns;
        }

        static getGroupByAggregates(fields: Array<Field>): Array<DataObjectFieldUtilitiesModule.IGroupByAggregates> {
            const groupByAggregates = fields.map<DataObjectFieldUtilitiesModule.IGroupByAggregates | null>(field => {
                if ("groupByAggregate" in field) {
                    const { name, groupByAggregate, alias } = field;
                    
                    return { name, groupByAggregate, alias };
                }

                if ('aggregate' in field) {
                    const { name, aggregate, alias } = field;
                    
                    return { name, groupByAggregate: aggregate, alias };
                }

                return null;
            }).filter(item => item != null) as Array<DataObjectFieldUtilitiesModule.IGroupByAggregates>;

            return groupByAggregates;
        }

        static getGroupByColumns(fields: Array<Field>): Array<DataObjectFieldUtilitiesModule.IGroupByColumns> {
            const groupByColumns = fields.map<DataObjectFieldUtilitiesModule.IGroupByColumns | null>(field => {
                if ("groupByOrder" in field) {
                    const { name, groupByOrder } = field;
                    return { name, groupByOrder }
                }

                return null;
            }).filter(item => item);

            return self.FastSort.sort(groupByColumns).asc(u => u["groupByOrder"]);
        }

        static getSortingArray<T>(sortColumns: Array<DataObjectFieldUtilitiesModule.ISortColumns>): ISortByObjectSorter<T> | Array<ISortByObjectSorter<T>> {
            if (sortColumns.length === 1) {
                const sortBy = sortColumns[0].name;
                const direction = sortColumns[0].sortDirection;

                return this._getSortDirectionArray(direction, sortBy);
            } else if (sortColumns.length > 1) {
                const sortArray = sortColumns.map(item => this._getSortDirectionArray(item.sortDirection, item.name));

                return sortArray;
            }

            return [];
        }

        private static _getSortDirectionArray(direction: SortDirection, sortBy: string) {
            switch (direction) {
                case SortDirection.Asc:
                    return { asc: (x: any) => x[sortBy] };
                case SortDirection.Desc:
                    return { desc: (x: any) => x[sortBy] };
            }
        }
    }

    self.o365.exportScripts({ DataObjectFieldUtilities, SortDirection, GroupByAggregates });
})();